@value min-medium, desktop-s from '@/styles/variables.module.css';

.container {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  height: 60px;
}

.homePageHeader {
  text-indent: -9999px;
}

.innerContainer {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  width: 100%;
  max-width: 1440px;
}

.leftWrapper {
  margin-right: 24px;
  width: 24px;

  @media min-medium {
    flex: 1;
  }
}

.centerWrapper,
.rightWrapper {
  position: relative;
  flex: 1;
}

.centerWrapper {
  text-align: center;

  @media min-medium {
    justify-content: center;
  }
}

.logo {
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translateY(-50%);

  width: 66px;
  height: 22px;

  @media desktop-s {
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90px;
    height: 30px;
  }
}

.rightWrapper {
  display: flex;
  justify-content: flex-end;
}

.burger {
  position: relative;
  cursor: pointer;
  border: none;
  background-color: transparent;
}

.userInfo {
  display: flex;
  align-items: center;
}
