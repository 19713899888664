.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.buttonWrapper {
  display: flex;
  align-self: flex-end;
  gap: 20px;
  width: 50%;
  height: 30px;
}
